import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"

import Layout from "../../components/Layout/Layout"
import GlobalContext from "../../context/GlobalContext"
import { labels } from "../../constants/label.constants"
import { setDataLayer } from "../../helpers/dataLayerSetter"
import { LISTING_FILTER_TYPE } from "../../constants/listing.constants"
import FilterWrapper from '../../components/UI/Filter/FilterWrapper'
import { searchHeaderGtm } from '../../constants/gtm.constants'
import { getUrlValues, updatePageUrl } from '../../helpers/search'
import Button from "../../components/UI/Button/Button"
import { gTagScriptConsent } from "../../components/Scripts/Gtm.Head"

const SearchResults = ({ path, pageContext }) => {
    const { globalProps, seoData, pageType, dataLayer, globalLabels, brandTheme } = pageContext
    const { mainTitle, subTitle } = pageContext?.pageProps?.contentfulSearchResults || {}
    const [query, setQuery] = useState("")
    const [queryToFetch, setQueryToFetch] = useState("")
    const [recipesTotal, setRecipesTotal] = useState(0)
    const [productsTotal, setProductsTotal] = useState(0)
    const [activeTab, setActiveTab] = useState(LISTING_FILTER_TYPE.RECIPE)

    setDataLayer(dataLayer, seoData, pageType)

    const handleSearchChange = e => {
        setQuery(e?.target?.value)
    }

    const fetchData = (q) => {
        if (typeof window !== "undefined") {
            if (window?.dataLayer) {
                searchHeaderGtm.GAeventLabel = q || query
                window.dataLayer.push({ ...searchHeaderGtm })
            }
        }

        setQueryToFetch(q || query)
    }

    const onSubmit = () => {
        updatePageUrl({ search: query })
    }

    useEffect(() => {
        const urlParams = getUrlValues({})
        const urlQuery = urlParams.search

        if (urlQuery) {
            setQuery(urlQuery)
            fetchData(urlQuery)
        }
    }, [])

    return (
        <GlobalContext labels={globalLabels} brandTheme={brandTheme}>
            <Layout path={path} seoData={seoData} globalProps={globalProps}>
                {/*{mainTitle && (*/}
                {/*    <SearchResultHero*/}
                {/*        title={mainTitle}*/}
                {/*        description={subTitle?.subTitle}*/}
                {/*        placeholder={labels?.search}*/}
                {/*        query={query}*/}
                {/*        onChange={handleSearchChange}*/}
                {/*        onSearchSubmit={onSubmit}*/}
                {/*    />*/}
                {/*)}*/}

                {/*<SearchHeader query={queryToFetch} resultsCount={recipesTotal + productsTotal}/>*/}

                <div className="max-w-[1440px] mx-auto px-[25px] pt-[52px] pb-[48px] ">
                    <div className={'h-0 w-0 overflow-hidden'}
                        aria-live={"polite"}>{activeTab === LISTING_FILTER_TYPE.RECIPE ? 'Recipes tab selected.' : 'Products tab selected'}</div>

                    <div className={'flex flex-col md:flex-row justify-between items-center'}>
                        <div
                            className={'font-RobotoMono tracking-[-0.02em] font-bold text-[20px] leading-[33px] text-primary uppercase mb-[24px] md:mb-0'}>{`${activeTab === LISTING_FILTER_TYPE.RECIPE ? recipesTotal : productsTotal} results`}</div>
                        <div className={'flex gap-[20px]'}>
                            <Button
                                layout={"outline-round"}
                                size={"s"}
                                className={clsx(
                                    "!w-[auto] hover:!bg-primary !py-[13px] !px-[30px]",
                                    activeTab === LISTING_FILTER_TYPE.RECIPE ? "bg-primary text-white" : ""
                                )}
                                outlineColor="primary"
                                onClick={() => setActiveTab(LISTING_FILTER_TYPE.RECIPE)}
                            >
                                {labels?.searchRecipes}
                            </Button>

                            <Button
                                layout={"outline-round"}
                                size={"s"}
                                className={clsx(
                                    "!w-[auto] hover:!bg-primary !py-[13px] !px-[30px]",
                                    activeTab === LISTING_FILTER_TYPE.PRODUCT ? "bg-primary text-white" : ""
                                )}
                                outlineColor="primary"
                                onClick={() => setActiveTab(LISTING_FILTER_TYPE.PRODUCT)}
                            >
                                {labels?.searchProducts}
                            </Button>
                        </div>
                    </div>
                </div>

                <div className={clsx(activeTab === LISTING_FILTER_TYPE.RECIPE ? "block" : "hidden")}>
                    <FilterWrapper
                        type={LISTING_FILTER_TYPE.RECIPE}
                        isSearchPage={true}
                        query={queryToFetch}
                        onFetchDone={totalRecipes => setRecipesTotal(totalRecipes)}
                        globalLabels={globalLabels}
                    />
                </div>

                <div className={clsx(activeTab === LISTING_FILTER_TYPE.PRODUCT ? "block" : "hidden")}>
                    <FilterWrapper
                        type={LISTING_FILTER_TYPE.PRODUCT}
                        isSearchPage={true}
                        query={queryToFetch}
                        onFetchDone={totalProducts => setProductsTotal(totalProducts)}
                        globalLabels={globalLabels}
                    />
                </div>
            </Layout>
        </GlobalContext>
    )
}

SearchResults.propTypes = {
    pageContext: PropTypes.shape({
        pageProps: PropTypes.object,
        globalProps: PropTypes.object,
        seoData: PropTypes.object,
        pageType: PropTypes.string,
        dataLayer: PropTypes.object,
        globalLabels: PropTypes.object,
        brandTheme: PropTypes.object,
    }),
    path: PropTypes.string,
    mainTitle: PropTypes.string,
    subTitle: PropTypes.object,
}

/**
 * Adding to Layout ?
 */
export const Head = () => {
    const dataObject = typeof window !== "undefined" ? window?.gtmDataLayer : {}

    return (
        <>
            <script
                id="first-unique-id"
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: `var MdlzdataLayer = ${JSON.stringify(dataObject)};`,
                }}
            />
            <script
                async
                type="text/javascript"
                dangerouslySetInnerHTML={{ __html: gTagScriptConsent }}
            />
        </>
    )
}

export default SearchResults
